
interface IModalChild{
    tittel: string;
    melding: string;
}

const ModalChildContent = ({tittel, melding}: IModalChild) => {
    return (
        <div className="modal--children">
            <span className="icon icon-varsel_stor" />
            <div className="modal--children-content">
                <h2 className="modal--children__title">{tittel}</h2>
                <span className="modal--children__message">{melding}</span>
            </div>
        </div>
    );
}

export default ModalChildContent;