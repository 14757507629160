import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { removeSpaces } from '../../../../shared/lib/utils';
import api from '../../shared/lib/api';
import { IKundeFull, KontaktpersonTyperEnum, KontaktpersonTyperNavn, OrganisasjonsformEnum } from '../../shared/lib/types';
import { getRadgiverFullName } from '../kundeHelper';
import TilbakeTil from '../Tilbake';
import Spinner from '../../spinner/Spinner';
import FeilmeldingSide from '../../feilside/FeilmeldingSide';
import Modal from '../../../modal/Modal';
import ModalChildContent from '../../../modal/ModalChildContent';
import toast from 'react-hot-toast';

type TParams = {
    kundeId: string;
};
const MAX_ITEM = 4;
const Kundedetaljer = () => {
    const { kundeId } = useParams<TParams>();
    let navigate = useNavigate();
    const [kunde, setKunde] = useState<IKundeFull>();
    const [loading, setLoading] = useState<boolean>(true);
    const [serverErrorCode, setServerErrorCode] = useState<number>(0);

    useEffect(() => {
        (() => {
            api.getKunde(kundeId)
                .then((kunde) => {
                    setKunde(kunde);
                })
                .catch((res) => setServerErrorCode(res.status))
                .finally(() => setLoading(false));

            document.title = 'KS Komplett - Kundedetaljer';
        })();
    }, [kundeId]);

    function onDeleteKundeClick() {
        api.deleteKunde(kundeId)
            .then((res) => {
                navigate('/kunder', { replace: true });
            })
            .catch(() => toast.error(`Kunne ikke slette kunde "${kunde.navn}"`));
    }

    if (loading) {
        return <Spinner loading={loading} />;
    }

    if (serverErrorCode) {
        return <FeilmeldingSide errorCode={serverErrorCode} url="/kunder" />;
    }

    return (
        <>
            <TilbakeTil url={`/kunder/${removeSpaces(kundeId)}/kundeside`} tekst="Tilbake til kundeside" />
            <div className="page">
                <div className="container">
                    <div className="section section--white">
                        {kunde && (
                            <>
                                <h1>{kunde.navn}</h1>

                                <div className="kundedetaljer">
                                    <div className="kundedetaljer__content">
                                        <div>
                                            <div className="kundedetaljer-information-kunde">
                                                <h3>INFORMASJON OM KUNDE</h3>
                                                <ul className="list--unstyled">
                                                    <li>
                                                        <b>Organisasjonsnummer</b>: {kunde.organisasjonsnummer}
                                                    </li>
                                                    <li>
                                                        <b>Firmanavn</b>: {kunde.navn}
                                                    </li>
                                                    {kunde.organisasjonsform !== OrganisasjonsformEnum.undefined && (
                                                        <li>
                                                            <b>Organisasjonsform</b>: {OrganisasjonsformEnum[kunde.organisasjonsform]}
                                                        </li>
                                                    )}
                                                    {kunde.telefonnummer && (
                                                        <li>
                                                            <b>Telefon</b>: {kunde.telefonnummer}
                                                        </li>
                                                    )}
                                                    {kunde.mobilnummer && (
                                                        <li>
                                                            <b>Mobiltelefon</b>: {kunde.mobilnummer}
                                                        </li>
                                                    )}
                                                    {kunde.epost && (
                                                        <li>
                                                            <b>Epost</b>: <a href={`mailto:${kunde.epost}`}>{kunde.epost}</a>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                            {kunde.kundeansvarlig && (
                                                <div className="kundedetaljer-kundeansvarlig">
                                                    <h3>KUNDEANSVARLIG</h3>
                                                    <p>{getRadgiverFullName(kunde.kundeansvarlig)}</p>
                                                </div>
                                            )}

                                            {kunde.harBegrensetKundesynlighet && (
                                                <div className="kundedetaljer-rettigheter">
                                                    <h3>RETTIGHETER</h3>
                                                    <ul className="list--unstyled">
                                                        <li>{getRadgiverFullName(kunde.kundeansvarlig, false) + ' (kundeansvarlig)'}</li>
                                                        {kunde.rettigheter.slice(0, MAX_ITEM).map((radgiver) => {
                                                            return <li key={radgiver.brukerId}>{getRadgiverFullName(radgiver)}</li>;
                                                        })}
                                                        {kunde.rettigheter.length > MAX_ITEM && (
                                                            <li>
                                                                <span>...og {kunde.rettigheter.length - MAX_ITEM} personer til</span>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            )}
                                            {kunde.gateadresse && (
                                                <div className="kundedetaljer-forretningsadresse">
                                                    <h3>FORRETNINGSADRESSE</h3>
                                                    <ul className="list--unstyled">
                                                        {kunde.gateadresse?.adresse && (
                                                            <li>
                                                                <b>Gateadresse</b>: {kunde.gateadresse?.adresse}
                                                            </li>
                                                        )}
                                                        {kunde.gateadresse?.postnummer && (
                                                            <li>
                                                                <b>Postnummer</b>: {kunde.gateadresse?.postnummer}
                                                            </li>
                                                        )}
                                                        {kunde.gateadresse?.poststed && (
                                                            <li>
                                                                <b>Poststed</b>: {kunde.gateadresse?.poststed}
                                                            </li>
                                                        )}

                                                        {kunde.gateadresse?.landkode && (
                                                            <li>
                                                                <b>Landkode</b>: {kunde.gateadresse?.landkode}
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            )}
                                            {kunde.postadresse && (
                                                <div className="kundedetaljer-postadresse">
                                                    {' '}
                                                    <h3>POSTADRESSE</h3>
                                                    <ul className="list--unstyled">
                                                        {kunde.postadresse?.adresse && (
                                                            <li>
                                                                <b>Gateadresse</b>: {kunde.postadresse?.adresse}
                                                            </li>
                                                        )}
                                                        {kunde.postadresse?.postnummer && (
                                                            <li>
                                                                <b>Postnummer</b>: {kunde.postadresse?.postnummer}
                                                            </li>
                                                        )}
                                                        {kunde.postadresse?.poststed && (
                                                            <li>
                                                                <b>Poststed</b>: {kunde.postadresse?.poststed}
                                                            </li>
                                                        )}
                                                        {kunde.postadresse?.landkode && (
                                                            <li>
                                                                <b>Landkode</b>: {kunde.postadresse?.landkode}
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            )}
                                            {kunde.dagligLeder && (
                                                <div className="kundedetaljer-dagligleder">
                                                    <h3>DAGLIG LEDER</h3>

                                                    <ul className="list--unstyled">
                                                        {kunde.dagligLeder?.fornavn && (
                                                            <li>
                                                                <b>Fornavn</b>: {kunde.dagligLeder?.fornavn}
                                                            </li>
                                                        )}
                                                        {kunde.dagligLeder?.etternavn && (
                                                            <li>
                                                                <b>Etternavn</b>: {kunde.dagligLeder?.etternavn}
                                                            </li>
                                                        )}
                                                        {kunde.dagligLeder?.epost && (
                                                            <li>
                                                                <b>Epost</b>: {kunde.dagligLeder?.epost}
                                                            </li>
                                                        )}
                                                        {kunde.dagligLeder?.mobilnummer && (
                                                            <li>
                                                                <b>Mobilnummer</b>: {kunde.dagligLeder?.mobilnummer}
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            )}

                                            {!!kunde.kontaktpersoner?.length && (
                                                <div className="kundedetaljer-kontaktpersoner">
                                                    <h3>ANDRE KONTAKTPERSONER</h3>
                                                    <ul className="list--unstyled">
                                                        {kunde.kontaktpersoner.map((kontaktperson) => {
                                                            return (
                                                                <li key={kontaktperson.id}>
                                                                    <ul className="list--unstyled">
                                                                        {kontaktperson.rolle.type !== KontaktpersonTyperEnum.NotSet && (
                                                                            <li>
                                                                                <b>Rolle</b>: {KontaktpersonTyperNavn[kontaktperson.rolle.type]}
                                                                            </li>
                                                                        )}
                                                                        <li>
                                                                            <b>Fornavn</b>: {kontaktperson.fornavn}
                                                                        </li>
                                                                        <li>
                                                                            <b>Etternavn</b>: {kontaktperson.etternavn}
                                                                        </li>
                                                                        {kontaktperson.epost && (
                                                                            <li>
                                                                                <b>Epost</b>: {kontaktperson.epost}
                                                                            </li>
                                                                        )}
                                                                        {kontaktperson.mobilnummer && (
                                                                            <li>
                                                                                <b>Mobilnummer</b>: {kontaktperson.mobilnummer}
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {
                                        <div className="kundedetaljer__actions">
                                            <div>
                                                <div className="vstack">
                                                    <Link to={`/kunder/${kundeId}/rediger`} className="btn btn--primary btn-edit">
                                                        Rediger
                                                    </Link>
                                                    {kunde.kundeansvarlig?.innloggetRadgiver && (
                                                        <Modal
                                                            triggerText="Slett kunde"
                                                            triggerClassName="btn btn--secondary btn-edit"
                                                            footer={
                                                                <>
                                                                    <button
                                                                        className="btn btn--secondary btn--sm"
                                                                        data-bs-dismiss="modal"
                                                                        onClick={onDeleteKundeClick}
                                                                    >
                                                                        Slett
                                                                    </button>
                                                                    <button className="btn btn--primary btn--sm" data-bs-dismiss="modal">
                                                                        Avbryt
                                                                    </button>
                                                                </>
                                                            }
                                                            children={
                                                                <ModalChildContent
                                                                    tittel="Slette kunde"
                                                                    melding={`Er du sikker på at du vil slette ${kunde.navn}? Dokumentarkiv og kundedata vil bli slettet permanent.`}
                                                                />
                                                            }
                                                        ></Modal>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Kundedetaljer;
